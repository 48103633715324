import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import * as styles from "./NavBar.module.css";
import { StaticImage } from "gatsby-plugin-image";
import YouTubeIcon from "../icon/youtube.svg";
import TwitterIcon from "../icon/twitter.svg";
import { useWindowDimensions } from "./windowHooks";

const NavLink = ({ name, to }) => {
  return (
    <Link
      className={styles.navbarItem}
      activeClassName={styles.navbarItemActive}
      to={to}
    >
      {name}
    </Link>
  );
};

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const menuClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    open && menuRef.current.focus();
  }, [open]);

  return (
    <>
      <button
        aria-label="toggle burger menu"
        className={styles.burgerIcon}
        onClick={menuClick}
        onKeyDown={menuClick}
      >
        <span></span>
      </button>
      <div
        className={`${styles.burgerMenu} ${open ? styles.open : ""} `}
        onClick={menuClick}
        onKeyDown={menuClick}
        ref={menuRef}
        onBlur={() => setOpen(false)}
        tabIndex={0}
        role="group"
      >
        <NavLink to="/" name="About" />
        <NavLink to="/works" name="Works" />
        <NavLink to="/guideline" name="Guideline" />
        <NavLink to="/secret" name="？？？" />
      </div>
    </>
  );
};

const NavBar = () => {
  const { width } = useWindowDimensions();
  const isSSR = typeof window === "undefined";
  return (
    <nav className={styles.navbarContainer}>
      <div className={styles.logo}>
        <Link to={"/"}>
          <StaticImage
            src="../images/logo.png"
            width={300}
            placeholder="none"
            loading="eager"
          />
        </Link>
      </div>
      <div className={styles.icons}>
        <a href="http://twitter.com/Yoshgan_Tzarino">
          <TwitterIcon />
        </a>
        <a href="http://youtube.com/channel/UCy0-r48VVqXoSXNw_b5lh8Q">
          <YouTubeIcon />
        </a>
      </div>
      {!isSSR &&
        (width < 670 ? (
          <BurgerMenu />
        ) : (
          <div className={styles.links}>
            <NavLink to="/" name="About" />
            <NavLink to="/works" name="Works" />
            <NavLink to="/guideline" name="Guideline" />
            <NavLink to="/secret" name="？？？" />
          </div>
        ))}
    </nav>
  );
};

export default NavBar;
