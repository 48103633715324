import * as React from "react";
import NavBar from "./NavBar";
import Seo from "./Seo";

const Layout = ({ pageTitle, children }) => {
  return (
    <>
      <Seo title={pageTitle} />
      <NavBar />
      <main>{children}</main>
    </>
  );
};

export default Layout;
