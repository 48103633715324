import { useState, useEffect, useCallback } from "react";

export const useWindowDimensions = () => {
  const isClient = typeof window === "object";

  const getWindowDimensions = useCallback(() => {
    return {
      width: isClient ? window?.innerWidth : 2000,
      height: isClient ? window?.innerHeight : 2000,
    };
  }, [isClient]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [getWindowDimensions]);
  return windowDimensions;
};
