import React, { useState } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import * as style from "../components/Guideline.module.css";

const H2 = (props) => <h2 className={style.h2} {...props} />;

const P = (props) => <p className={style.p} {...props} />;

const components = {
  h2: H2,
  p: P,
};

const Guideline = ({ data }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <Layout pageTitle={"Guideline"}>
      <div className={style.wrapper}>
        <h2 className={style.h2}>Guideline</h2>
        <p className={style.p}>現在制定中......</p>
        <p className={style.p}>正式な文書になり次第更新予定</p>
        <button
          className={style.button}
          onClick={() => {
            setShowDetail(!showDetail);
          }}
        >
          <span>現状の方向性を表示する</span>
        </button>
        <div
          className={style.detail}
          style={showDetail ? { opacity: 1 } : { opacity: 0 }}
        >
          <MDXProvider components={components}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    mdx(slug: { eq: "guideline" }) {
      body
    }
  }
`;

export default Guideline;
